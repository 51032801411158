import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";


export enum AppColors {
  ACCENT = "#10419B",
  ACCENTHIGHLIGHT = "#36CEF0",
  BRIGHT = "#FFFFFF",
  PRIMARY = "#2C2738",
  STROKE = "#DBE2EA",
  SECONDARY = "#756F86",
  BACKGROUND = "#F5F5FB",
  TINT = "#E2F1FA",
  ICON = "#AFB6BD",
  SCUCCESS = "#15CF74",
  ERROR = "#FF7171",
  WARNING = "#FFAE33"
}

export enum ProductStatus {
  CREATED = "created",
  PENDING = "pending",
  PUBLISHED = "published"
}

export enum OrderStatus {
  PENDING = "pending",
  PROCESSING = "processing",
  CANCELLED = "cancelled",
  COMPLETED = "completed"
}

export enum DeliveryStatus {
  PENDING = "pending",
  DISPATCHED = "dispatched",
  DELIVERED = "delivered"
}

export enum RentStatus {
  PENDING = "pending",
  RENTED = "rented",
  RETURNED = "returned",
  EXPIRED = "expired"
}

export enum PaymentStatus {
  PENDING = "pending",
  COMPLETED = "completed",
  FAILED = "failed"
}

export enum AttributeDisplayType {
  COLORSWATCH = "colorswatch",
  TEXTWSWATCH = "textswatch",
  DROPDOWN = "dropdown"
}

export enum InstitutionStatus {
  ACTIVE = "active",
  INACTIVE = "inactive"
}

export enum PaymentModeValue {
  INSTITUTION = "PM_1",
  CUSTOMER = "PM_2"
}

export enum ShippingMethodValue {
  INSTITUTION = "SHIP_001",
  CUSTOMER = "SHIP_002"
}

export enum UserRole {
  CUSTOMER = "customer"
}

export enum PromotionType {
  BANNER = "banner",
  POPUP = "popup"
};

export type PromotionPosition = PopupPromotionPosition | BannerPromotionPosition;

export enum PopupPromotionPosition {
  TOP_LEFT = "top-left",
  TOP_RIGHT = "top-right",
  BOTTOM_LEFT = "bottom-left",
  BOTTOM_RIGHT = "bottom-right",
  CENTER = "center"
}

export enum BannerPromotionPosition {
  AREA1 = "area1",
  AREA2 = "area2",
  AREA3 = "area3",
  AREA4 = "area4",
  AREA5 = "area5"
}


/**
 * config class to remove labels from mat-paginator
 */
@Injectable()
export class AppPaginatorIntl extends MatPaginatorIntl {
  nextPageLabel = "";
  previousPageLabel = "";
}

export const UserStorageKey = "ssiLoggedInCustomer"
export const PersonalizationUserStorageKey = "ssiPersonalizationUser";
