import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmComponent } from "@ssi-core/component/dialog/confirm/confirm.component";
import { DeleteConfirmComponent } from '@ssi-core/component/dialog/delete-confirm/delete-confirm.component';
import { ConfirmData, DeleteConfirmData } from "@ssi-model/app.types";
import { BehaviorSubject, Subject } from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class UIManager {

  constructor(
    private matDialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  actionAlert = new Subject<AlertData>();

  cartCount$ = new BehaviorSubject<number>(0);

  /**
   * show delete confirmation
   *  
   * @param dialogData content for confirmation component 
   * @returns dialog reference
   */
  showDeleteConfirmation(dialogData: DeleteConfirmData) {

    return this.matDialog.open(
      DeleteConfirmComponent, {
      maxWidth: '30rem',
      width: "90%",
      autoFocus: false,
      panelClass: 'app-dialog',
      data: dialogData
    }
    )

  }

  /**
   * show general confirmation dialog
   *  
   * @param dialogData content for confirmation component 
   * @returns dialog reference
   */
  showConfirmation(dialogData: ConfirmData) {

    return this.matDialog.open(ConfirmComponent, {
      maxWidth: '30rem',
      width: "90%",
      autoFocus: false,
      panelClass: 'app-dialog',
      data: dialogData
    })

  }

  showFlash(message: string, background: string, time = 3000) {
    this.snackBar.open(message, '',
      {
        duration: time,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: ['bg-' + background, 'text-white']
      }
    );
  }

}

export interface AlertData {
  type: "error" | "success" | "warning";
  message: string;
}
