import { Component, OnInit } from '@angular/core';
import { SvgIconRegistryService } from 'angular-svg-icon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(private iconReg: SvgIconRegistryService) {}

  ngOnInit(): void {
    this.loadIcons();
  }

  loadIcons() {
    this.iconReg
      .loadSvg('assets/images/search-line.svg', 'search')
      ?.subscribe();
    this.iconReg
      .loadSvg('assets/images/account.svg', 'my-account')
      ?.subscribe();
    this.iconReg
      .loadSvg('assets/images/cart.svg', 'cart')
      ?.subscribe();
    this.iconReg.loadSvg('assets/images/phone.svg', 'phone')?.subscribe();
    this.iconReg.loadSvg('assets/images/mail.svg', 'mail')?.subscribe();
    this.iconReg.loadSvg('assets/images/add-to-cart.svg', 'add-to-cart')?.subscribe();
    this.iconReg
      .loadSvg('assets/images/right-arrow.svg', 'right-arrow')
      ?.subscribe();
    this.iconReg
      .loadSvg('assets/images/user-love.svg', 'user-love')
      ?.subscribe();
    this.iconReg.loadSvg('assets/images/trash.svg', 'trash')?.subscribe();
    this.iconReg.loadSvg('assets/images/tick.svg', 'tick')?.subscribe();
  }
}
