import {
  HttpErrorResponse,
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UIManager } from '@ssi-service/uimanager.service';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';

@Injectable()
/**
 * service for adding access token to server requests,
 * and refreshing access token in case of unauthorized error
 * 
 * Access token refresh mechanism :
 * 
 * 1) We listen for errors in request , If request failed as unauthorized we start refreshing token
 *    on refresh success, we resend the error request with newly obtained token
 * 2) If multiple request failed in sequence, for the first request we perform refreshing token 
 *    and other request are halted until refreshing is completed. On getting new access token
 *    halted requests are resend with new access token
 * 3) If refresh token request failed we signout user
 * 4) After attaching newly obtained access token, If resend request failed with unauthorized status
 *    we also signout user 
 * 
 * ref : https://stackoverflow.com/questions/45202208/angular-4-interceptor-retry-requests-after-token-refresh
 * 
 */
export class TokenInterceptor implements HttpInterceptor {

  // whether token refresh process is ongoing.
  tokenRefreshInProgress = false;

  // subject for notifying , token refreshing is completed
  tokenRefreshedSource = new Subject();
  // observable of token refresh notifier
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(
    private authService: AuthService,
    private matDialog: MatDialog,
    private uiManager: UIManager
  ) { }

  // apis that does not require authorization
  exceptionalUrls = [
    this.authService.loginInUrl
  ]

  // requests that need pre  refreshing of access token before actual request
  preRefreshRequests = [
    { method: 'GET', urlRegex: /^institutions\/\d+\/products$/ },
    { method: 'GET', urlRegex: /^products\/\d+$/ },
    { method: 'GET', urlRegex: /^cart$/ },
    { method: 'POST', urlRegex: /^cart$/ },
    { method: 'DELETE', urlRegex: /^cart\/\d+$/ },
  ]

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    /* 
     * check if the request is for an end point does not require authentication
     * 1) login , 2) req for images send by svg-icon library
     * 
     * in that case just pass request to next interceptor
     */
    if (this.exceptionalUrls.includes(request.url) || request.url.startsWith("assets/images")) {
      return next.handle(request);
    }


    // check if request need pre refreshing , 
    // if yes perform refreshing of access token before actual request 
    if (this.isPreRefreshNeeded(request)) {
      return this.refreshAndResendRequest(request, next)
    }

    // add auth header to req if logged in
    request = this.setAccessToken(request);


    // if request is for token refreshing ,just pass request to next interceptor
    if (request.url == this.authService.refreshUrl) {
      return next.handle(request);
    }

    //pass request to next interceptor 
    return next.handle(request)
      .pipe(
        // catch errors  for request
        catchError((error: HttpErrorResponse) =>
          this.handleError(request, next, error)
        )
      );
  }

  /**
   * check if the request need refreshing of access token before sending it 
   * @param req 
   * @returns 
   */
  isPreRefreshNeeded(req: HttpRequest<any>) {

    return this.authService.isLoggedIn() && this.preRefreshRequests.some(
      preRefreshRequest => req.method == preRefreshRequest.method &&
        preRefreshRequest.urlRegex.test(req.url)
    )

  }

  /**
   * if user logged in add auth token to header 
   * @param req 
   * @returns 
   */
  setAccessToken(
    request: HttpRequest<any>
  ) {

    // check user is signed in 
    if (this.authService.isLoggedIn()) {

      // add access token to request header
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${this.authService.getToken()}`
        }
      });

    }

    return request;

  }

  /**
   * handle errors for the request , refreshing token is initiated from this method
   * 
   * @param request 
   * @param next 
   * @param error 
   * @returns 
   */
  handleError(
    request: HttpRequest<any>,
    next: HttpHandler,
    error: HttpErrorResponse
  ) {

    // if request recieved unauthorized status
    if (error.status == 401) {

      /*
       * if user is not signed in yet and tried to access protected resource
       * redirect user instantly to login
       */
      if (!this.authService.isLoggedIn())
        this.logoutUser();
      else
        return this.refreshAndResendRequest(request, next)

    }

    // return the initial error
    return throwError(error)

  }

  /**
   * refresh access token and resend the request
   * 
   * @param request 
   * @param next  
   * @returns 
   */
  refreshAndResendRequest(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // initate refresh mechanism 
    return this.refreshToken()
      .pipe(

        // perform resending request after token is refreshed
        switchMap(() => {

          // add newly obtained access token to header
          request = this.setAccessToken(request);
          // resend the error request
          return next.handle(request)
        }),
        catchError((resendError: HttpErrorResponse) => {

          /*
           * Even after new token is obtained, if request resulted in 401
           * then signout user, to avoid looping  
           */
          if (resendError.status == 401) {

            if (resendError.error.data == "Token has been blocked")
              return this.refreshAndResendRequest(request, next);


            this.logoutUser({ showMessage: true });

          }


          // return the error occoured while resending
          return throwError(resendError);

        })

      )

  }

  /**
   * Process for getting new access token 
   * 
   * @returns 
   */
  refreshToken() {

    /*
     * if already token refreshing is in progress, 
     * halt the new request by returning an observable that will complete
     * when token is refreshed 
     */

    if (this.tokenRefreshInProgress) {

      /*
       * when token is refreshed complete the observable
       * and it will make  resending of the queued requests 
       */
      return new Observable(
        (subscriber) => {

          /*
           * when token is refreshed complete the observable
           * and it will make  resending of the queued requests 
           */
          this.tokenRefreshed$
            .subscribe(

              () => {
                subscriber.next();
                subscriber.complete();
              }

            )
        }
      )

    } else {

      // mark token refreshing is in progresss
      this.tokenRefreshInProgress = true;

      // fetch new token from server
      return this.authService.refreshToken()
        .pipe(
          tap(data => {

            // mark token refreshing as complete
            this.tokenRefreshInProgress = false;
            // trigger the queued requests
            this.tokenRefreshedSource.next();

          }),
          catchError((error: HttpErrorResponse) => {

            // mark token refreshing as complete
            this.tokenRefreshInProgress = false;
            // logout user if refreshing token fails
            this.logoutUser({ showMessage: true });

            return throwError(error);

          })
        )
    }

  }


  /**
   * logout the user from application
   */
  logoutUser(options?: { showMessage: boolean }) {

    this.authService.logout();

    // show a message to user if showMessage is true
    if (options?.showMessage)
      this.uiManager.showFlash("Session expired!", "danger", 5000)

    // close any open dialogs
    this.matDialog.closeAll();

  }


}
