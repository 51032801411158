import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path : "", loadChildren : () =>  import('./store/store.module').then( m => m.StoreModule) },
  { path : "", loadChildren : () => import('./auth/auth.module').then( m => m.AuthModule) },
  { path: '**', redirectTo:'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling :"enabled",
    scrollPositionRestoration : "enabled",
    onSameUrlNavigation : "reload"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
