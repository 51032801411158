import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Response } from '@ssi-model/app.types';
import { PersonalizationUserStorageKey, UserStorageKey } from '@ssi-model/app.values';
import { Login } from '@ssi-model/login';
import { LoggedInUser } from '@ssi-model/user';
import { addLoader } from '@ssi-service/loader.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private _loggedInUser: LoggedInUser | null = null;

  baseUrl = "auth";

  loginInUrl = `${this.baseUrl}/login`;
  refreshUrl = `${this.baseUrl}/refresh`;

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.loadUser();
    this.listenLocalStorageEvents();
  }

  isLoggedIn() {
    return this._loggedInUser != null;
  }

  getToken() {
    return this._loggedInUser!.access_token;
  }

  @addLoader
  login(credentials: Login, loaderId: string | null = null): Observable<any> {
    return this.http.post<Response>(this.loginInUrl, credentials).pipe(
      map(response => {
        return response.data;
      }),
      tap(data => {
        this.setUser({
          access_token: data.access_token,
          role: data.role
        })
      })
    );
  }

  refreshToken() {
    return this.http.post<Response>(this.refreshUrl, {})
      .pipe(
        map(response => {
          return response.data;
        }),
        tap(data => {
          this.updateToken(data.access_token)
        })
      )
  }

  setUser(user: LoggedInUser): void {

    this._loggedInUser = user;

    localStorage.setItem(UserStorageKey, JSON.stringify(this._loggedInUser));
    localStorage.setItem(PersonalizationUserStorageKey, JSON.stringify(this._loggedInUser));
  }

  loadUser() {

    let serializedData = localStorage.getItem(UserStorageKey);

    if (serializedData) {

      this._loggedInUser = JSON.parse(serializedData);

    }

  }

  updateToken(access_token: string): void {

    this._loggedInUser!.access_token = access_token;

    localStorage.setItem(UserStorageKey, JSON.stringify(this._loggedInUser));
    localStorage.setItem(PersonalizationUserStorageKey, JSON.stringify(this._loggedInUser));

  }

  logout(): void {
    this.clearSession();
    this.router.navigate(['login']);
  }

  clearSession(): void {
    this._loggedInUser = null;

    localStorage.removeItem(UserStorageKey);
    localStorage.removeItem(PersonalizationUserStorageKey);
  }

  /**
   * listen to local storage changes
   */
  listenLocalStorageEvents() {

    window.addEventListener("storage", (event) => {

      // compare customer data stored in localstorage with loaded in memory
      let storedUserData = localStorage.getItem(UserStorageKey);
      let serializedCurrentUserData = JSON.stringify(this._loggedInUser);

      if (storedUserData != serializedCurrentUserData) {

        // if data is changed 
        if (storedUserData) {

          // if user data is present in storage - update the data in memory

          this._loggedInUser = JSON.parse(storedUserData);

        } else {

          // if user data is not present in storage - logout user

          this._loggedInUser = null;
          this.router.navigate(['login']);

        }

      }

    })


  }

}
