import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
/**
 * By default , when there are undefined or empty params in a request,
 * it is added in request url as "null" or "undefined"
 * 
 * https://github.com/angular/angular/issues/18567
 * 
 * purpose of this interceptor is to avoid passing "null" or "undefined"
 */
export class EmptyParamInterceptor implements HttpInterceptor {
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    let params = req.params;

    for(let key of params.keys()) {

      // if value is undefined or null remove it 
      if(params.get(key) == undefined || params.get(key) == null) {
        params = params.delete(key);
      }

    }

    // return modified request 
    return next.handle( req.clone( { params }))

  }
  
}