import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BreadCrumbComponent } from './component/bread-crumb/bread-crumb.component';
import { ConfirmComponent } from './component/dialog/confirm/confirm.component';
import { DeleteConfirmComponent } from './component/dialog/delete-confirm/delete-confirm.component';
import { DropdownDirective } from './component/dropdown/dropdown.directive';
import { ButtonComponent } from './component/form-elements/button/button.component';
import { InputComponent } from './component/form-elements/input/input.component';
import { RadioInputComponent } from './component/form-elements/radio-input/radio-input.component';
import { SelectComponent } from './component/form-elements/select/select.component';
import { InputIncrementorComponent } from './component/input-incrementor/input-incrementor.component';
import { LoaderComponent } from './component/loader/loader.component';
import { SearchbarComponent } from './component/searchbar/searchbar.component';
import { FormElementCastPipe } from './pipe/form-element-cast.pipe';
import { PriceFormatPipe } from './pipe/price-format.pipe';
import { TrustHtmlPipe } from './pipe/trust-html.pipe';



@NgModule({
  declarations: [
    BreadCrumbComponent,
    ConfirmComponent,
    DeleteConfirmComponent,
    InputIncrementorComponent,
    FormElementCastPipe,
    TrustHtmlPipe,
    LoaderComponent,
    InputComponent,
    SelectComponent,
    ButtonComponent,
    DropdownDirective,
    RadioInputComponent,
    PriceFormatPipe,
    SearchbarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    AngularSvgIconModule.forRoot(),
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatRadioModule,
    LayoutModule,
    NgSelectModule

  ],
  exports: [
    AngularSvgIconModule,
    FlexLayoutModule,
    BreadCrumbComponent,
    MatDialogModule,
    ConfirmComponent,
    DeleteConfirmComponent,
    InputIncrementorComponent,
    FormElementCastPipe,
    FormsModule,
    ReactiveFormsModule,
    TrustHtmlPipe,
    LoaderComponent,
    InputComponent,
    SelectComponent,
    RadioInputComponent,
    ButtonComponent,
    SearchbarComponent,
    DropdownDirective,
    PriceFormatPipe
  ],
  providers: [
    DecimalPipe
  ]
})
export class CoreModule { }
