
<h3 class="title">{{data.title}}</h3>

<p>{{data.content}}</p>

<div class="button-container" fxLayoutAlign="end center" fxLayoutGap="12px" >

  <button class="btn btn-primary-outline" type="button" [mat-dialog-close]="false"  >
    <span>Cancel</span>
  </button>

  <button class="btn btn-primary action-button" type="button" [mat-dialog-close]="true" >
    <svg-icon svgClass="rect-24 m-r-1" [src]="data.actionIcon" ></svg-icon>
    <span>{{data.actionText}}</span>
  </button>

</div>
