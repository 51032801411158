import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";


@Injectable({
  providedIn : 'root'
})
export class LoaderService {

  loaderRefStore:LoaderRefsStore = LoaderRefsStore.getInstance();

  addLoader(loaderId:string) {
    this.loaderRefStore.add(loaderId);
  }

  removeLoader(loaderId:string) {
    this.loaderRefStore.remove(loaderId)
  }

  isLoaderActive(loaderId:string) {
    return this.loaderRefStore.doExist(loaderId)
  }

}

/** 
 * Class for storing loader references 
 * 
 * We using singleton design patterns to built this class 
 * 
 * singleton design pattern allows you get the same instance of the class
 * every time 
 * 
 */
class LoaderRefsStore {

  /**
   * constructor of the class is private so that 
   * the instantiation of class is only possible from this class only
   */
  private constructor() { }

  // current instance of class
  static instance:LoaderRefsStore | null = null;

  //object to store the loader ids
  loaderRefs:{ [key:string] : any } = {};

  //add the loader reference to store
  add(loaderId:string) {
    this.loaderRefs[loaderId] = true;
  }

  //remove the loader reference to store
  remove(loaderId:string) {
    delete this.loaderRefs[loaderId]
  }

  // check if loader reference exist in the store
  doExist(loaderId:string) {
    return !!this.loaderRefs[loaderId]
  }

  /**
   * get the singleton instance of the class
   * @returns singleton instance of the class
   */
  static getInstance() {

    /**
     * check if the instance of the class is already created 
     * if no then create a new instance. 
     * 
     * From this point this instance is returned
     * whever you try to get an instance 
     */
    if(!LoaderRefsStore.instance) {
      LoaderRefsStore.instance = new LoaderRefsStore();
    }

    // return the instance
    return LoaderRefsStore.instance;

  }

}


/**
 * this function is used as a decorator throughout application 
 * 
 * this function is executed whenever decorated method is executed
 * so there by we can add and remove loaders 
 * whenver a function performing http requests is executed
 * 
 * @param target Either the constructor function of the class for a static member, or the prototype of the class for an instance member.
 * @param propertyKey The name of the member.
 * @param descriptor The Property Descriptor for the member.
 * @returns 
 */
export function addLoader(target:any, propertyKey:any, descriptor:PropertyDescriptor) {
  
  // copy the original method to a variable
  const originalMethod = descriptor.value;

  // get instance of LoaderStore
  const loaderRefStore:LoaderRefsStore = LoaderRefsStore.getInstance();

  /**
   * modify the defintion of function 
   * ie we pipe the http observable return from original fnction 
   * 
   * @param args arguments of orginal fn , we make it an arrray using rest operator
   * @returns modified function definition
   */
  descriptor.value = function(...args: any){

    // the last param is reference to loader
    let loaderId = args[args.length - 1];

    // execute the method with its params , and this as its class reference
    let returnValue:Observable<any> = originalMethod.apply(this, args);

    /**
     * if loader is passed , we need to modify function definition
     * else return the execution result
     */
    if(loaderId != null) {

      // added loader to store
      loaderRefStore.add(loaderId);
      
      returnValue = returnValue.pipe(
          /**
           * tap the return value 
           * remove the loader , when request succeed, failed or completed
           */
          tap( 
            res => {
              loaderRefStore.remove(loaderId);
            },
            err => {
              loaderRefStore.remove(loaderId);
            },
            () => {
              loaderRefStore.remove(loaderId);
            }
          ),
          // when the http request is cancelled , also remove the loader
          finalize(() => {
            loaderRefStore.remove(loaderId);
          })
        )
    } 

    // return the descriptor 
    return returnValue;

  }

  return descriptor;

}