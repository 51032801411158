<img class="title-image" src="assets/images/alarm.svg" alt="alarm">

<h3>{{data.title}}</h3>

<p>{{data.content}}</p>

<div class="button-container">

  <button class="button button-default" type="button" [mat-dialog-close]="false">
    <span>Cancel</span>
  </button>

  <button class="button button-accent action-button" type="button" [mat-dialog-close]="true">
    <!-- <svg-icon svgClass="rect-24" src="assets/images/trash.svg"></svg-icon> -->
    <span>{{data.actionText}}</span>
  </button>

</div>