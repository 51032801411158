import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { UIManager } from '@ssi-service/uimanager.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private _router: Router,
    private ui: UIManager
  ) { }

  /**
   * Check of error messages act on them
   * @param request request
   * @param next next
   * @returns Observable
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        switch (err.status) {
          /*case 401: {
            this._api.logout();
            break;
          }*/
          case 422: {
            break;
          }
          case 500: {
            this.ui.showFlash("Oops! Something went wrong.", "danger", 5000)
            break;
          }
          default: {
            // statements;
            break;
          }
        }
        return throwError(err);
      })
    );
  }
}
