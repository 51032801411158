import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from './../../../environments/environment';


@Injectable()
/**
 * this interceptor is used to intercept the api requests 
 * and add the domain details to requests
 */
export class UrlInterceptor implements HttpInterceptor {
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    /** 
     * check if the request is for image ( send by svg-icon lib ) 
     * 
     * in that case just pass request to next interceptor
     */
    if(req.url.startsWith("assets/images")) {
      return next.handle(req)
    }

    // add the domain details
    return next.handle(
      req.clone({
        url: `${environment.storeFrontUrl}/${req.url}`,
      })
    );

  }
  
}